import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/pages/Home.vue';
import ReefCamPage from '../components/pages/ReefCam.vue';
import ReefAppPage from '../components/pages/ReefApp.vue';
import PhotogrammetryPage from '../components/pages/Photogrammetry.vue';
import ReefMapPage from '../components/pages/ReefMap.vue';
import CommunitySciencePage from '../components/pages/CommunityScience.vue';
import DataExplorerPage from '../components/pages/DataExplorer.vue';
// BLOG
import BlogIndex from '@/components/pages/BlogIndex.vue';
import BlogPost from '@/blog/BlogPost.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/reefcam',
    name: 'reefcam',
    component: ReefCamPage
  },
  {
    path: '/reefapp',
    name: 'reefapp',
    component: ReefAppPage
  },
  {
    path: '/photogrammetry',
    name: 'photogrammetry',
    component: PhotogrammetryPage
  },
  {
    path: '/reefmap',
    name: 'reefmap',
    component: ReefMapPage
  },
  {
    path: '/community-science',
    name: 'community-science',
    component: CommunitySciencePage
  },
  {
    path: '/data-explorer',
    name: 'data-explorer',
    component: DataExplorerPage
  },
  {
    path: '/blog',
    name: 'BlogIndex',
    component: BlogIndex,
  },
  {
    path: '/blog/:year/:slug',
    name: 'BlogPost',
    component: BlogPost,
    props: true,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;
