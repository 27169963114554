<template>
  <section id="reefcam" class="container mx-auto py-12 px-6 md:py-20 md:px-10 lg:px-20 xl:px-40 flex flex-col items-center gap-12">
    <div class="flex flex-col gap-3 md:text-left">
      <div>
        <h2 class="gradient-text text-display-lg font-black">REEFCAM</h2>
      </div>
      <div>
        <p class="text-lg">ReefCam is an advanced underwater monitoring system designed to enhance reef conservation
          efforts through state-of-the-art technology. Equipped with powerful AI capabilities, ReefCam offers a suite
          of innovative features</p>
      </div>
    </div>
    <div class="flex justify-center">
      <img src="@/assets/images/reefcam-both.png" alt="ReefCam Image" class="lg:w-1/2 h-auto" />
    </div>
    <div class="flex justify-center">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto text-left">
        <div class="flex flex-col gap-3">
          <h3 class="display-2xl font-black">AI FISH DETECTION</h3>
          <p class="text-base">Automatically identifies and tracks various fish species in real-time, providing crucial
            data on fish populations and behavior.</p>
        </div>
        <div class="flex flex-col gap-3">
          <h3 class="display-2xl font-black">3D POINT CLOUD</h3>
          <p class="text-base">Generates a detailed 3D depth map of the reef environment, providing information on fish length, size and biomass. </p>
        </div>
        <div class="flex flex-col gap-3">
          <h3 class="display-2xl font-black">TIMELAPSE</h3>
          <p class="text-base">Captures a series of images over time, creating visual records that reveal long-term
            ecological processes and changes.</p>
        </div>
      </div>
    </div>
    <!-- LOTTIE FOR 3D CAM -->
    <div class="flex justify-center items-center w-full">
      <lottie-player id="reefcamLottie" ref="reefcamLottie" mode="normal" :src="scrollAnimationUrl" class="transform-gpu scale-[1.9] py-20 lg:py-0 lg:scale-125 w-full lg:h-screen">
      </lottie-player>
    </div>
    <!-- REEFCAM MAX -->
    <div class="text-left lg:text-center">
      <h3 class="text-2xl font-bold mb-4">REEFCAM MAX</h3>
      <p class="text-lg">ReefCam Max is our in-house designed camera and sensor suite, purpose-built for capturing comprehensive scientific data on reef ecosystems. This advanced system collects high-resolution images, generates 3D point cloud depth data, and creates automatic timelapses to track reef changes over time. ReefCam Max goes beyond visual data, recording audio for bioacoustic analysis and monitoring water temperature. Its modular design allows for seamless integration with our broader underwater hardware suite, making it a versatile and expandable tool for marine research and conservation efforts.</p>
    </div>
  </section>
</template>

<script>
import '@lottiefiles/lottie-player';
import { create } from '@lottiefiles/lottie-interactivity';
import scrollAnimationData from '@/assets/lottie-files/reefcam-max.json';

export default {
  name: 'ReefCamSection',
  data() {
    return {
      scrollAnimationUrl: URL.createObjectURL(new Blob([JSON.stringify(scrollAnimationData)], { type: 'application/json' })),
    };
  },
  methods: {
    setupReefcamInteractivity() {
      create({
        player: '#reefcamLottie',
        mode: 'scroll',
        actions: [
          {
            visibility: [0, 1],
            type: 'seek',
            frames: [1, 120],
          },
        ],
      });
    },
  },
  mounted() {
    this.$refs.reefcamLottie.addEventListener('load', this.setupReefcamInteractivity);
  }
}
</script>

<style scoped>
</style>
