<template>
  <header
    :class="[
      'lg:bg-transparent lg:backdrop-blur-none backdrop-blur-lg text-white w-full flex justify-between items-center px-6 h-20 fixed top-0 left-0 z-50 transition-all duration-500 border-b-0.5 border-border-ui',
      { 'lg:border-none': !isMenuOpen },
      headerClass,
    ]"
  >
    <div class="flex items-center">
      <button @click="toggleMenu" class="block lg:hidden mr-4">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </button>
      <a href="/" class="flex items-center space-x-2">
        <img src="@/assets/logo/reefos-logomark.svg" alt="ReefOS Logo" class="w-40 h-auto" />
      </a>
    </div>
    <nav :class="['lg:flex lg:py-4 lg:px-5 lg:border-0.5 lg:rounded-full lg:bg-gradient-framed-gray lg:border-border-ui lg:backdrop-blur-xl space-x-5 hidden', { block: isMenuOpen }]">
      <a
        v-for="(link, index) in navLinks"
        :key="index"
        :href="`#${link.id}`"
        class="font-riposte-thin text-text-xs hover:text-primary-500"
      >
        {{ link.name }}
      </a>
    </nav>
    <a href="/contact" class="bg-primary-500 px-5 py-2 text-base rounded-full hidden lg:block">Contact Us</a>
  </header>
  <transition name="slide-fade">
    <div
      v-if="isMenuOpen"
      class="bg-black text-white lg:hidden fixed top-20 left-0 w-full h-full z-20 transition-transform transform duration-300 ease-in-out"
    >
      <nav class="flex flex-col items-start gap-3 px-6 py-4 space-y-2">
        <a
          v-for="(link, index) in navLinks"
          :key="index"
          :href="`#${link.id}`"
          class="font-riposte-thin text-display-md hover:text-primary-500"
        >
          {{ link.name }}
        </a>
        <a href="/contact" class="bg-primary-500 px-5 py-2 text-base text-center w-full rounded-full">Contact Us</a>
      </nav>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      isMenuOpen: false,
      navLinks: [
        { name: 'ReefCam', id: 'reefcam' },
        { name: 'ReefApp', id: 'reefapp' },
        { name: 'Photogrammetry', id: 'photogrammetry' },
        { name: 'ReefMap', id: 'reefmap' },
        { name: 'Community Science', id: 'community-science' },
        { name: 'Data Explorer', id: 'data-explorer' },
				{ name: 'Blog', id: 'blog' },
      ],
      headerClass: '',
      lastScrollY: 0,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      document.body.classList.toggle('overflow-hidden', this.isMenuOpen);
    },
    handleScroll() {
      if (window.scrollY > this.lastScrollY) {
        this.headerClass = '-translate-y-full';
      } else {
        this.headerClass = 'translate-y-0';
      }
      this.lastScrollY = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    document.body.classList.remove('overflow-hidden');
  },
};
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
