<template>
  <section id="community-science" class="container mx-auto flex flex-col items-center text-center gap-12 py-12 px-6 md:py-20 md:px-10 lg:px-20 xl:px-40">
    <div class="flex flex-col items-center gap-10 w-full">
      <div class="flex flex-col gap-5 w-full">
        <h2 class="gradient-text text-display-lg font-black">COMMUNITY SCIENCE APP</h2>
        <p class="text-lg px-4 md:px-10">Our Community Science App empowers citizen scientists worldwide to contribute to reef conservation. With a comprehensive library of live camera feeds and a custom-built labeling tool, users can actively participate in tracking reef recovery and biodiversity.</p>
      </div>
      <button class="btn-primary bg-primary-500 px-5 py-2 text-base rounded-full">Join the Community</button>
      <div class="flex justify-center w-full">
        <FramedImageHolder src="community-science-appDisplay.png" alt="Community Science App Interface" />
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto text-left">
      <div class="flex flex-col gap-3">
        <h3 class="text-2xl font-bold">LIVE CAMERA LIBRARY</h3>
        <p class="text-base">Access a vast collection of live underwater camera feeds from various reef locations, providing real-time views of marine ecosystems.</p>
      </div>
      <div class="flex flex-col gap-3">
        <h3 class="text-2xl font-bold">CUSTOM LABELING TOOL</h3>
        <p class="text-base">Utilize our intuitive labeling tool to identify and tag marine species, contributing valuable data to our reef recovery tracking efforts.</p>
      </div>
      <div class="flex flex-col gap-3">
        <h3 class="text-2xl font-bold">GAMIFIED EXPERIENCE</h3>
        <p class="text-base">Engage in friendly competition with a fun leaderboard system and earn badges for your contributions, making conservation both educational and entertaining.</p>
      </div>
    </div>
  </section>
</template>

<script>
import FramedImageHolder from '../FramedImageHolder.vue';

export default {
  name: 'CommunityScienceSection',
  components: {
    FramedImageHolder
  }
}
</script>

<style scoped>
</style>
