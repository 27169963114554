<template>
  <div class="home flex-column align-center overflow-hidden">
    <!-- HERO-->
    <HeroSection />
    <!-- MISSION -->
    <MissionSection />
    <!-- REEFCAM -->
    <ReefCamSection />
    <ReefCamCarousel />
    <!-- REEFAPP -->
    <ReefAppSection />
    <!-- PHOTOGRAMMETRY-->
    <PhotogrammetrySection />
    <!-- REEFMAP -->
    <ReefMapSection />
    <!-- COMMUNITY SCIENCE -->
    <CommunityScienceSection />
    <!-- BLOG SECTION -->
    <BlogSection />
  </div>
</template>

<script>
import HeroSection from '../sections/HeroSection.vue';
import MissionSection from '../sections/MissionSection.vue';
import ReefCamSection from '../sections/ReefCamSection.vue';
import ReefCamCarousel from '@/components/ReefCamCarousel.vue';
import ReefAppSection from '@/components/sections/ReefAppSection.vue';
import PhotogrammetrySection from '../sections/PhotogrammetrySection.vue';
import ReefMapSection from '@/components/sections/ReefMapSection.vue';
import CommunityScienceSection from '@/components/sections/CommunityScienceSection.vue';
import BlogSection from '@/components/sections/BlogSection.vue';
import FramedImageHolder from '@/components/FramedImageHolder.vue';

export default {
  name: 'Home',
  components: {
    HeroSection,
    MissionSection,
    ReefCamSection,
    ReefCamCarousel,
    ReefAppSection,
    PhotogrammetrySection,
    CommunityScienceSection,
    BlogSection,
    FramedImageHolder,
    ReefMapSection,
  },
};
</script>

<style scoped></style>
