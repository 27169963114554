<template>
  <section id="photogrammetry" class="container mx-auto flex flex-col items-center text-center gap-12 py-12 px-6 md:py-20 md:px-10 lg:px-20 xl:px-40">
    <div class="flex flex-col items-center gap-10 w-full">
      <div class="flex flex-col gap-5 w-full">
        <h2 class="gradient-text text-display-lg font-black">Photogrammetry Toolkit</h2>
        <p class="text-lg lg:w-8/12 self-center px-4 md:px-10">Our Photogrammetry Toolkit is a purpose-built 3D reef analysis package that revolutionizes coral tracking and observation. This advanced software allows us to annotate and monitor individual corals within our high-precision photogrammetry models, providing unprecedented insights into reef ecosystems.</p>
      </div>
      <div class="flex justify-center w-full">
        <FramedImageHolder class="w-10/12 h-auto" src="pg-screen.png" alt="Photogrammetry Toolkit Interface" />
      </div>
      <div class="flex flex-col gap-8 md:flex-row md:gap-12 w-full mt-8">
        <div class="flex-1">
          <h3 class="text-2xl font-bold mb-3">3D AI Coral Detection</h3>
          <p class="text-base">Our toolkit employs a sophisticated 3D AI model to automatically identify and locate corals within the photogrammetry models, streamlining the analysis process.</p>
        </div>
        <div class="flex-1">
          <h3 class="text-2xl font-bold mb-3">Detailed Annotations</h3>
          <p class="text-base">Scientists can easily add comprehensive observations and data points to each identified coral, creating a rich, interactive database of reef information.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FramedImageHolder from '../FramedImageHolder.vue';

export default {
  name: 'PhotogrammetrySection',
  components: {
    FramedImageHolder
  }
}
</script>

<style scoped>
</style>
