<template>
  <div id="app">
    <Navbar />
    <router-view />
  </div>
</template>

<script>
import '@/assets/style/main.css';
import Navbar from './components/Navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar
  }
}
</script>

<style>
html {
  scroll-behavior: smooth;
}

/* Style for the scrollbar track */
::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

/* Style for the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #333;
  /* Dark color for the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners */
  border: 3px solid transparent;
  /* Add some padding */
  background-clip: content-box;
  /* Padding inside the thumb */
}

/* Style for the scrollbar track */
::-webkit-scrollbar-track {
  background: #1f1f1f;
  /* Dark background for the track */
  border-radius: 10px;
  /* Rounded corners */
}

/* Hover state for the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Slightly lighter color for hover state */
}
</style>
