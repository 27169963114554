<template>
  <section id="mission" class="container mx-auto py-12 px-6 md:py-20 md:px-10 lg:px-20 xl:px-40 flex flex-wrap lg:flex-nowrap justify-center gap-3">
    <div class="w-full lg:w-1/2">
      <h2 class="gradient-text text-display-lg font-black">WE BUILD AI-POWERED TECHNOLOGIES FOR REEF CONSERVATION</h2>
    </div>
    <div class="w-full lg:w-1/2">
      <p class="text-lg">At our organization, we harness the power of artificial intelligence to develop cutting-edge
        technologies aimed at conserving and restoring the world’s coral reefs. By leveraging advanced machine
        learning algorithms, we analyze vast amounts of ecological data to monitor reef health, predict environmental threats,
        and devise effective conservation strategies.</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MissionSection'
}
</script>

<style scoped>
</style>
