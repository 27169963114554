<template>
  <section class="blog-index container mx-auto py-12 px-6 md:py-20 md:px-10 lg:px-20 xl:px-40">
    <h2 class="text-3xl font-bold mb-8">All Blog Posts</h2>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div v-for="(post, index) in blogPosts" :key="index" class="block rounded-lg overflow-hidden shadow-lg">
        <router-link :to="post.path">
          <img :src="post.coverImage" alt="cover image" class="w-full h-48 object-cover" />
          <div class="p-4">
            <h3 class="text-xl font-bold">{{ post.title }}</h3>
            <p class="text-gray-600">{{ post.excerpt }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { blogPosts } from '@/blog/BlogData';

export default {
  name: 'BlogIndex',
  data() {
    return {
      blogPosts,
    };
  },
};
</script>

<style>
/* Add any necessary styles here */
</style>
