<template>
  <div class="w-full h-full flex flex-col justify-center items-center gap-8">
    <div
      class="w-full h-auto px-4 py-4 md:px-6 md:py-6 lg:px-7 lg:py-7 rounded-2xl lg:rounded-3xl border-0.5 border-ui backdrop-blur-sm flex flex-col justify-center items-center"
      :style="{ background: 'linear-gradient(111deg, rgba(239, 239, 239, 0.12) 1.01%, rgba(239, 239, 239, 0.08) 99.18%)' }">
      <div
        class="w-full h-full rounded-xl lg:rounded-2xl overflow-hidden border-0.5 border-ui flex flex-col justify-center items-center">
        <img class="w-full h-full object-cover" :src="resolvedSrc" :alt="alt" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FramedImageHolder',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
  },
  computed: {
    resolvedSrc() {
      return require(`@/assets/images/${this.src}`);
    },
  },
};
</script>

<style scoped>
</style>
