<template>
  <section id="blog" class="container-fluid mx-auto flex flex-col gap-12 pb-20">
    <h2 class="gradient-text text-display-lg font-black self-center">CG LABS BLOG</h2>
    <Splide :options="splideOptions" class="splide">
      <SplideSlide v-for="(post, index) in displayedPosts" :key="index">
        <div class="blog-card flex w-full h-full justify-between flex-col border-0.5 rounded-[24px] p-6">
          <div class="flex flex-col overflow-hidden shadow-lg">
            <img :src="post.coverImage" alt="cover image"
              class="flex w-full h-full object-cover rounded-2xl border-0.5" />
            <div class="flex flex-col gap-3 pt-6">
              <h3 class="gradient-text text-display-md font-black">{{ post.title }}</h3>
              <p class="text-lg line-clamp-3">{{ post.excerpt }}</p>
            </div>
          </div>
          <router-link :to="post.path" class="self-start mt-4">
            <button
              class="bg-transparent text-white hover:text-black border-0.5 border-white hover:border-primary-500 hover:bg-primary-500 hover:scale-105 transition-all duration-200 px-5 py-2 text-base rounded-full">
              Read the article
            </button>
          </router-link>
        </div>
      </SplideSlide>
      <SplideSlide>
        <div class="blog-card flex flex-col h-full border-0.5 rounded-[24px]">
          <router-link to="/blog" class="flex h-full justify-center items-center rounded-[24px]">
            <div class="p-4 text-center">
              <h3 class="text-xl font-bold">See All Blog Posts</h3>
            </div>
          </router-link>
        </div>
      </SplideSlide>
    </Splide>
  </section>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { blogPosts } from '@/blog/BlogData';
import '@splidejs/vue-splide/css'; // Import default Splide CSS

export default {
  name: 'BlogSection',
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      blogPosts,
      splideOptions: {
        type: 'slide',
        perPage: 2,
        gap: '1.5rem',
        padding: '15rem',
        perMove: 1,
        speed: 1000,
        snap: true,
        pagination: false,
        arrows: false,
        trimSpace: false,
        breakpoints: {
          640: {
            perPage: 1,
            padding: '1.5rem',
            arrows: false,
            perMove: 1,
          },
          1024: {
            perPage: 2,
          },
        },
      },
    };
  },
  computed: {
    displayedPosts() {
      return this.blogPosts.slice(0, 5); // Show the latest 5 posts
    },
  },
};
</script>

<style>
.blog-card {
  background: linear-gradient(111deg, rgba(239, 239, 239, 0.12) 1.01%, rgba(239, 239, 239, 0.08) 99.18%);
  backdrop-filter: blur(27.5px);
}
</style>
