<template>
  <div class="blog-post container mx-auto py-12 px-6 md:py-20 md:px-10 lg:px-20 xl:px-40">
    <div class="text-sm text-text-secondary font-riposte-thin mb-6">{{ post.date }}</div>
    <h1 class="text-display-xl w-9/12 gradient-text font-black mb-6 self-center">{{ post.title }}</h1>
    <img :src="post.coverImage" alt="Cover Image" class="w-full h-auto mb-6 rounded-lg shadow-lg" />
    <div v-html="post.content" class="prose max-w-none"></div>
    <div class="flex gap-4 justify-center mb-12">
      <button class="bg-transparent text-white border-0.5 border-white px-5 py-2 text-base rounded-full">
        Share article
      </button>
      <i class="fab fa-facebook"></i>
      <i class="fab fa-twitter"></i>
      <i class="fab fa-linkedin"></i>
    </div>
    <p class="text-center text-gray-500">&copy; 2024 CG Labs. All rights reserved.</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      post: {},
    };
  },
  created() {
    this.loadPost();
  },
  watch: {
    '$route'(to, from) {
      if (to.params.slug !== from.params.slug) {
        this.loadPost();
      }
    }
  },
  methods: {
    loadPost() {
      const { year, slug } = this.$route.params;
      import(`@/blog/${year}/${slug}.vue`).then(module => {
        const postComponent = module.default;
        this.post = {
          ...postComponent.meta,
          content: postComponent.data().content,
        };
        window.scrollTo(0, 0); // Scroll to top
      }).catch(error => {
        console.error(`Error loading post: ${year}/${slug}`, error);
        this.$router.push('/not-found');
      });
    },
  },
};
</script>

<style scoped>
.prose img {
  width: 100%;
  height: auto;
}
</style>
