<template>
    <div>
        Data Explorer Page
    </div>
</template>

<script>
export default {
    name: 'DataExplorer'
}
</script>

<style scoped></style>
