const context = require.context('@/blog', true, /\.vue$/);

export const blogPosts = context.keys().map(key => {
  try {
    const post = context(key).default;
    const year = key.split('/')[1];
    if (!post.meta) {
      throw new Error(`Missing meta in ${key}`);
    }
    return {
      ...post.meta,
      path: `/blog/${year}/${post.meta.slug}`,
    };
  } catch (error) {
    console.error(`Error loading ${key}:`, error);
    return null;
  }
}).filter(post => post !== null);

// Sort the blog posts by date in descending order
blogPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

export default blogPosts;
