<template>
  <section id="hero" class="flex flex-col items-center justify-center text-center h-dvh">
    <lottie-player id="hero-lottie" class="h-3/4 transform-gpu" ref="heroLottie" mode="normal"
      :src="heroAnimationUrl"></lottie-player>
    <h1 class="flex flex-col justify-center mt-4">
      <span class="text-display-lg font-black">THE MOST ADVANCED</span>
      <br />
      <span class="text-display-3xl font-black">AI FOR THE REEF</span>
    </h1>
  </section>
</template>

<script>
import '@lottiefiles/lottie-player';
import heroAnimationDesktop from '@/assets/lottie-files/mergedAnimation.json';
import heroAnimationMobile from '@/assets/lottie-files/HeroAnimationMobile.json';

export default {
  name: 'HeroSection',
  data() {
    return {
      heroAnimationUrl: '',
    };
  },
  methods: {
    setupHeroInteractivity() {
      const player = this.$refs.heroLottie.getLottie();
      player.play();
      player.addEventListener('complete', () => {
        player.playSegments([120, 421], true); // Loop between frames 120 and 421
      });
    },
    updateHeroAnimationUrl() {
      const isMobile = window.matchMedia('(max-width: 768px)').matches;
      const selectedAnimation = isMobile ? heroAnimationMobile : heroAnimationDesktop;
      this.heroAnimationUrl = URL.createObjectURL(new Blob([JSON.stringify(selectedAnimation)], { type: 'application/json' }));
    }
  },
  mounted() {
    this.updateHeroAnimationUrl();
    this.$refs.heroLottie.addEventListener('load', this.setupHeroInteractivity);
    window.addEventListener('resize', this.updateHeroAnimationUrl);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateHeroAnimationUrl);
  }
};
</script>

<style scoped>
h1 {
  line-height: .7;
}
</style>
