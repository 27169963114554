<template>
  <div class="blog-post container mx-auto py-12 px-6 md:py-20 md:px-10 lg:px-20 xl:px-40">
    <div class="text-sm text-text-secondary font-riposte-thin mb-6">{{ meta.date }}</div>
    <h1 class="text-display-xl w-9/12 gradient-text font-black mb-6 self-center">{{ meta.title }}</h1>
    <img :src="meta.coverImage" alt="Cover Image" class="w-full h-auto mb-6 rounded-lg shadow-lg" />
    <div v-html="content" class="prose max-w-none"></div>
    <div class="flex gap-4 justify-center mb-12">
      <button class="bg-transparent text-white border-0.5 border-white px-5 py-2 text-base rounded-full">
        Share article
      </button>
      <i class="fab fa-facebook"></i>
      <i class="fab fa-twitter"></i>
      <i class="fab fa-linkedin"></i>
    </div>
    <p class="text-center text-gray-500">&copy; 2025 CG Labs. All rights reserved.</p>
  </div>
</template>

<script>
export default {
  meta: {
    title: "New Year Blog Post",
    excerpt: "This is a blog post for 2025.",
    coverImage: "https://placehold.co/1280x720",
    slug: "new-year-post",
    date: "2025-01-01",
    socialMedia: {
      facebook: "https://facebook.com/share/new-year-post",
      twitter: "https://twitter.com/share/new-year-post",
      linkedin: "https://linkedin.com/share/new-year-post"
    }
  },
  data() {
    return {
      content: `
        <p>
          Underwater camera technology has seen significant advancements...
        </p>
        <img src="https://placehold.co/1280x720" alt="Underwater Camera" class="w-full h-auto mb-6 rounded-lg" />
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit...
        </p>
      `
    };
  }
};
</script>

<style scoped>
.prose img {
  width: 100%;
  height: auto;
}
</style>
