<template>
  <section id="reefapp" class="container mx-auto py-12 px-6 md:py-20 md:px-10 lg:px-20 xl:px-40 flex flex-col items-center gap-12">
    <div class="flex flex-col md:flex-row items-center gap-12 w-full">
      <div class="flex-1 text-left">
        <h2 class="gradient-text text-display-lg font-black">REEFAPP</h2>
        <p class="text-lg mt-4">ReefApp is an innovative iPhone application designed to revolutionize coral monitoring in nurseries and outplant sites. By streamlining the monitoring process, ReefApp achieves a remarkable 10x increase in efficiency, allowing researchers and conservationists to collect vital data with unprecedented speed and accuracy.</p>
      </div>
      <div class="flex-1 flex justify-center">
        <img src="@/assets/images/reefapp-mockup.png" alt="ReefApp Image" class="w-4/5 md:w-8/12 h-auto rounded-2xl" />
      </div>
    </div>
    <div class="w-auto">
      <VideoSection headline="REVOLUTIONIZING CORAL MONITORING" />
    </div>
    <div class="flex flex-col md:flex-row-reverse items-center gap-12 w-full">
      <div class="flex-1 text-left">
        <h2 class="gradient-text text-display-lg font-black">CREATE 3D MODELS FROM PHOTOS</h2>
        <p class="text-lg mt-4">ReefApp integrates cutting-edge photogrammetry technology, enabling users to create state-of-the-art 3D models of coral structures. This feature, combined with comprehensive tracking of survival rates, health indicators, bleaching events, and growth rates, provides researchers with a powerful all-in-one tool for coral reef conservation and study.</p>
      </div>
      <div class="flex-1 flex justify-center">
        <img src="@/assets/images/reefapp-scan.png" alt="ReefApp Scan" class="w-auto md:w-full h-auto" />
      </div>
    </div>
  </section>
</template>

<script>
import VideoSection from '@/components/sections/VideoSection.vue';

export default {
  name: 'ReefAppSection',
  components: {
    VideoSection
  }
};
</script>

<style scoped>
</style>
