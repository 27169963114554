<template>
  <div class="reef-cam-carousel container mx-auto md:py-20 md:px-10 lg:px-20 xl:px-40 flex flex-col items-center gap-6">
    <Splide
      aria-label="Reef Cam Carousel"
      :options="mainOptions"
      ref="mainSplide"
    >
      <SplideSlide v-for="(slide, index) in slides" :key="index">
        <img :src="slide.src" :alt="slide.alt" class="w-full rounded-2xl" />
      </SplideSlide>
    </Splide>

    <Splide
      aria-label="Thumbnails for Reef Cam Carousel"
      :options="thumbsOptions"
      ref="thumbsSplide"
      class="text-text-secondary overflow-x-auto"
    >
      <SplideSlide v-for="(slide, index) in slides" :key="index" @click="goToSlide(index)">
        <span class="thumbnail-text flex justify-center items-center h-full text-sm px-5 py-2">
          {{ slide.alt }}
        </span>
      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { ref, onMounted } from 'vue';
import '@splidejs/vue-splide/css';

export default {
  name: 'ReefCamCarousel',
  components: {
    Splide,
    SplideSlide,
  },
  setup() {
    const mainSplide = ref(null);
    const thumbsSplide = ref(null);

    const slides = [
      { src: require('@/assets/images/rgb-image.jpg'), alt: 'RGB Image' },
      { src: require('@/assets/images/depth-map.jpg'), alt: 'Depth Map' },
      { src: require('@/assets/images/ai-fish-detection.jpg'), alt: 'AI Fish Detection' },
      { src: require('@/assets/images/3d-point-cloud.jpg'), alt: '3D Point Cloud' },
      { src: require('@/assets/images/timelapse.jpg'), alt: 'Timelapse' },
    ];

    const mainOptions = {
      type: 'loop',
      perPage: 1,
      pagination: false,
      arrows: false,
      drag: false,
      gap: '1.5rem',
      padding: '1.5rem',
      breakpoints: {
        640: {
          type: 'scroll',
          focus: 'center',
          drag: true,
        },
        768: {
        },
        1024: {
        },
      },
    };

    const thumbsOptions = {
      type: 'scroll',
      perPage: 5,
      pagination: false,
      arrows: false,
      autoWidth: true,
      autoHeight: true,
      gap: '1rem',
      drag: false,
      breakpoints: {
        640: {
          type: 'scroll',
          focus: 'center',
          drag: true,
        },
        768: {
        },
        1024: {
        },
      },
    };

    onMounted(() => {
      if (thumbsSplide.value) {
        mainSplide.value.sync(thumbsSplide.value.splide);
      }
    });

    const goToSlide = (index) => {
      mainSplide.value.go(index);
      thumbsSplide.value.go(index);
    };

    return {
      slides,
      mainSplide,
      thumbsSplide,
      mainOptions,
      thumbsOptions,
      goToSlide,
    };
  },
};
</script>

<style scoped>
.splide__slide.is-active .thumbnail-text {
  @apply border-0.5 rounded-full text-white delay-0;
}
</style>
