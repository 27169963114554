// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyAo7Jg0nHQuJdHsjD-oWt12BdPEUy7a304',
  authDomain: 'website-15ecd.firebaseapp.com',
  projectId: 'website-15ecd',
  storageBucket: 'website-15ecd.appspot.com',
  messagingSenderId: '410809557098',
  appId: '1:410809557098:web:44b090e1922a6a1fe2aa13',
  measurementId: 'G-N3YR7ED9EK'
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const app = createApp(App);

app.config.compilerOptions.isCustomElement = tag => tag === 'lottie-player';

app.use(router);
app.use(store);
app.mount('#app');
