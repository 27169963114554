<template>
  <div class="flex justify-center items-center flex-col relative w-dvw h-dvh">
    <video class="w-full h-full object-cover video-untouchable" autoplay loop muted playsinline>
      <source src="/videos/reefapp-video.webm" type="video/webm" />
      <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
      </p>
    </video>
    <h2 class="absolute z-10 text-center font-black text-white text-display-xl">{{ headline }}</h2>
  </div>
</template>

<script>
export default {
  name: 'VideoSection',
  props: {
    headline: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.ensureVideoAttributes();
  },
  methods: {
    ensureVideoAttributes() {
      const video = this.$el.querySelector('video');
      video.setAttribute('autoplay', 'true');
      video.setAttribute('loop', 'true');
      video.setAttribute('muted', 'true');
      video.setAttribute('playsinline', 'true'); // for iOS Safari to autoplay
    },
  },
};
</script>

<style scoped>
.video-untouchable {
  pointer-events: none;
}
</style>
