<template>
    <div>
        ReefCam Page
    </div>
</template>

<script>
export default {
    name: 'ReefCam'
}
</script>

<style scoped></style>
