<template>
    <div>
        ReefApp Page
    </div>
</template>

<script>
export default {
    name: 'ReefApp'
}
</script>

<style scoped></style>
