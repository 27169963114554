<template>
  <section id="reefmap"
    class="container mx-auto flex flex-col items-center gap-12 px-6 py-12 md:px-10 md:py-20 lg:flex-row lg:px-20 xl:px-40">
    <div class="container flex flex-col lg:w-1/2 flex-auto gap-10 lg:flex-col lg:shrink-0">
      <div class="flex flex-col gap-5">
        <h2 class="gradient-text text-display-lg font-black">REEFMAP</h2>
        <p class="text-lg">ReefMap is our cutting-edge geospatial visualization dashboard, providing real-time insights into coral reef ecosystems. Powered by our ReefOS database, it offers a dynamic and interactive platform for monitoring and analyzing reef data.</p>
      </div>
      <div class="grid grid-cols-2 gap-5 lg:gap-10">

        <div class="feature-item flex flex-row lg:flex-col p-2 lg:p-5 border border-0.5 border-border-ui rounded-md lg:rounded-xl gap-2 cursor-pointer transition duration-200 hover:bg-white"
          @click="changeImage('realtime-data')" :class="{ 'active bg-white text-black': activeTab === 'realtime-data' }">
          <img src="@/assets/icons/radio.svg" class="w-5 h-5 lg:w-6 lg:h-6 feature-icon" alt="Real-Time Data Icon" />
          <h3 class="text-sm lg:text-lg font-black">REAL-TIME DATA</h3>
          <p class="text-base hidden lg:inline">Live updates from our ReefOS database, ensuring up-to-the-minute accuracy.</p>
        </div>

        <div class="feature-item flex flex-row lg:flex-col p-2 lg:p-5 border border-0.5 border-border-ui rounded-md lg:rounded-xl gap-2 cursor-pointer transition duration-200 hover:bg-white"
          @click="changeImage('fish-detection')" :class="{ 'active bg-white text-black': activeTab === 'fish-detection' }">
          <img src="@/assets/icons/coral.svg" class="w-5 h-5 lg:w-6 lg:h-6 feature-icon" alt="Fish Detection Icon" />
          <h3 class="text-sm lg:text-lg font-black">ECOSYSTEM ANALYSIS</h3>
          <p class="text-base hidden lg:inline">Comprehensive insights into coral health, fish populations, and biodiversity.</p>
        </div>

        <div class="feature-item flex flex-row lg:flex-col p-2 lg:p-5 border border-0.5 border-border-ui rounded-md lg:rounded-xl gap-2 cursor-pointer transition duration-200 hover:bg-white"
          @click="changeImage('livestream')" :class="{ 'active bg-white text-black': activeTab === 'livestream' }">
          <img src="@/assets/icons/live.svg" class="w-5 h-5 lg:w-6 lg:h-6 feature-icon" alt="Livestream Icon" />
          <h3 class="text-sm lg:text-lg font-black">INTERACTIVE MAPPING</h3>
          <p class="text-base hidden lg:inline">Explore reef locations with dynamic, zoomable maps and data overlays.</p>
        </div>

        <div class="feature-item flex flex-row lg:flex-col p-2 lg:p-5 border border-0.5 border-border-ui rounded-md lg:rounded-xl gap-2 cursor-pointer transition duration-200 hover:bg-white"
          @click="changeImage('live-impact')" :class="{ 'active bg-white text-black': activeTab === 'live-impact' }">
          <img src="@/assets/icons/analytics.svg" class="w-5 h-5 lg:w-6 lg:h-6 feature-icon" alt="Live Impact Icon" />
          <h3 class="text-sm lg:text-lg font-black">IMPACT TRACKING</h3>
          <p class="text-base hidden lg:inline">Monitor conservation efforts and their effects on reef ecosystems in real-time.</p>
        </div>

      </div>
    </div>
    <div class="container lg:flex-none">
      <div class="relative w-full">
        <FramedImageHolder class="lg:w-11/12 h-auto" :src="currentImage" :alt="activeTab" />
      </div>
    </div>
  </section>
</template>

<script>
import FramedImageHolder from '@/components/FramedImageHolder.vue';

export default {
  components: {
    FramedImageHolder,
  },
  data() {
    return {
      activeTab: 'realtime-data',
      images: {
        'realtime-data': 'rm-realtime-data.png',
        'fish-detection': 'rm-fish-detection.png',
        'livestream': 'rm-livestream.png',
        'live-impact': 'rm-live-impact.png',
      },
    };
  },
  computed: {
    currentImage() {
      return this.images[this.activeTab];
    },
  },
  methods: {
    changeImage(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style scoped>
.feature-item:hover,
.feature-item:hover h3,
.feature-item:hover p {
  color: black;
}

.feature-item.active {
  background: white;
  color: black;
}

.feature-item.active h3,
.feature-item.active p {
  color: black;
}

.feature-item:hover .feature-icon,
.feature-item.active .feature-icon {
  filter: invert(1);
}
</style>
